<template>
  <main class="work">
    <div class="work-c">
      <section class="l-work-subsection align-center" id="executive-recruitment" data-scroll-section>
        <div class="main-features">
          <h1 class="title title-big title-general" data-scroll="fade-up" data-scroll-delay="1">The Work: Succesion Planning</h1>
          <ul class="main-list">
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="1">
              <div class="c-card c-card-lines-white">
                <figure class="icon"><img alt="exchange" :src="require(`@/assets/work/icon-client-profile.svg`)" /></figure>
                <h3 class="subtitle">Client Profile</h3>
                <div class="text-c">
                  <p class="text">&bull; Private business with a 30-year track record of continuous growth</p>
                  <p class="text">&bull; Leading player in the global shipping and port management industry</p>
                  <p class="text">&bull; Company had been run from its inception by two founders, who built the business between them</p>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="2">
              <div class="c-card c-card-lines-white">
                <figure class="icon"><img alt="exchange" :src="require(`@/assets/work/icon-client-situation.svg`)" /></figure>
                <div class="text-c">
                  <h3 class="subtitle">Client Situation</h3>
                  <div class="text-c">
                    <p class="text">Both founders were close to retirement and deeply concerned with both CEO and COO succession.</p>
                    <p class="text">In addition to industry-specific competency, the business depends on a powerful network of existing relationships with constituents in the private and public sectors.</p>
                    <p class="text">The core question: how to ensure the right successors given the complexity of the equation?</p>
                  </div>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="3">
              <div class="c-card c-card-lines-white">
                <figure class="icon"><img alt="exchange" :src="require(`@/assets/work/icon-diagnostic-finding.svg`)" /></figure>
                <div class="text-c">
                  <h3 class="subtitle">Diagnostic Finding</h3>
                  <div class="text-c">
                    <p class="text">Our team conducted its proprietary Diagnostic discovery process with the two founders, to understand each of them in-depth, as well as to identify the key drivers of success in the business model.</p>
                    <p class="text">During the process, these business leaders learned they each had a great degree of self-awareness and business insight, providing clues about the skills and behaviors needed in their successors.</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="bespoke-solution align-center">
          <h1 class="title title-small title-general" data-scroll="fade-up" data-scroll-delay="1"><span>Ascentador</span>Bespoke Solution</h1>
          <h3 class="highlight" data-scroll="fade-up" data-scroll-delay="2">Leveraging our competency in organizational dynamics and executive development our team translated the Diagnostic findings into a Competency Framework. We then conducted and in-depth assessment of the company’s entire executive leadership team.</h3>
          <ul class="main-list">
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="1">
              <div class="c-card">
                <span class="number">1</span>
                <h3 class="subtitle">Provide External Validation</h3>
                <div class="text-c">
                  <p class="text">Our findings confirmed that no succession candidates were available inside the business in the time frame identified by the founders. This external, objective validation dramatically decreased owner anxiety as well as political dissonance among the jockeying internal audiences.</p>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="2">
              <div class="c-card">
                <span class="number">2</span>
                <div class="text-c">
                  <h3 class="subtitle">Unearth Core Competencies</h3>
                  <div class="text-c">
                    <p class="text">We provided essential inputs on the core developmental needs, type of experiences, and timelines to ensure a successful transition. We developed a second alternative in the event the founders decided they were unwilling to elongate their succession, providing a competency map and extrapolated core competencies required for the position.</p>
                  </div>
                </div>
              </div>
            </li>
            <li class="main-item" data-scroll="fade-up" data-scroll-delay="3">
              <div class="c-card">
                <span class="number">3</span>
                <div class="text-c">
                  <h3 class="subtitle">Develop Internal Capability</h3>
                  <div class="text-c">
                    <p class="text">Unable initially to accept the idea of an external successor, but convinced by the data that no internal possiblities existed, our client postponed their retirement and instead opted for a series of executive development plans for key employees, sending some professionals for external training/continuing education.</p>
                    <p class="text">Though they ultimately recognized that the development of skills would require several years, they ultimately selected a mixed strategy, retaining Ascentador to conduct an executive search to find a new CEO for the business while using our development work to develop the new COO from within the group.</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'SuccessionPlanning',
  metaInfo() {
      return { 
          title: "Ascentador | The Work",
          meta: [
              { name: 'description', content:  'The Diagnostic Process brings together a team of expert consultants and key client stakeholders prior to launching any project. Using a proprietary form of guided questioning, the goal is to gain insight, uncover a lack of alignment and reveal unspoken agendas before laying the groundwork for the development of lateral solutions.'},
          ]
      }
  },
  data() {
    return {};
  },
  mounted() {
    this.$emit('view-mounted');
  },
  // mounted() {
  //   this.$emit('toggle-submenu-views');
  // }
};
</script>
